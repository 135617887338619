import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode
};

export function Group({ children }: Props) {
  return (
    <StyledGroup>
      {children}
    </StyledGroup>
  );
}

const StyledGroup = styled.div`
  *:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  *:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  *:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }
`;
