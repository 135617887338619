import styled from '@emotion/styled/macro';
import { FieldProps } from 'formik';
import React from 'react';

import { DropdownOption } from './CreatableDropdown';
import { Dropdown } from './Dropdown';

type Props = FieldProps & {
  label: string,
  options?: Array<{
    label: string,
    value: string
  }>
};

export function Select({ field, form, options, label }: Props) {
  const error = form.errors[field.name];
  const hasError = !!(error && form.touched[field.name]);

  return (
    <StyledWrapper>
      <StyledLabel htmlFor={field.name}>
        {label}:
        {hasError && (
          <StyledError>{error}</StyledError>
        )}
      </StyledLabel>
      <Dropdown
        name={field.name}
        value={options && options.find((option) => option.value === field.value)}
        onChange={(option) => option && !(option instanceof Array) && (
          form.setFieldValue(field.name, (option as DropdownOption).value)
        )}
        onBlur={field.onBlur}
        placeholder=""
        options={options}
        error={hasError}
        styles={{
          container: (provided) => ({
            ...provided,
            marginBottom: '.5rem'
          })
        }}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  color: rgba(0, 0, 0, 0.503991);
`;

const StyledError = styled.span`
  color: red;
`;
