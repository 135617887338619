import styled from '@emotion/styled/macro';
import { Select } from '../Select';
import { Field, Form, Formik } from 'formik';
import { CalendarInput } from '../Input';
import { format, startOfDay, subWeeks } from 'date-fns';
import { Button } from '../Button';
import { validate } from '../../utils/validate';
import { isRequired } from '../../validators/isRequired';
import { useRequest } from '../../hooks/useRequest';
import { Method } from '../../types';
import { toast } from 'react-toastify';

const ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];
const EXPORT_TYPES = {
  'routes/packaging/csv': 'Fusten',
  'orders/csv': 'Bestellingen',
  'users/csv': 'Klanten'
};
const EXPORTS_WITHOUT_RANGE = ['users/csv'];
const START_OF_DAY = startOfDay(new Date());

export function ExportTab() {
  const request = useRequest();

  return (
    <StyledTab>
      <Formik
        initialValues={{ type: '', startDate: subWeeks(START_OF_DAY, 1), endDate: START_OF_DAY }}
        onSubmit={({ type, startDate, endDate }) => {
          const payload = EXPORTS_WITHOUT_RANGE.indexOf(type) >= 0 ? undefined : {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
          };

          request(type, Method.Post, payload)
            .then((data) => {
              const blob = new Blob([data.csv], { type: 'text/csv' });
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');

              a.href = url;
              a.download = `${EXPORT_TYPES[type].toLocaleLowerCase()}-${format(startDate, 'dd-MM-yyyy')}-${format(endDate, 'dd-MM-yyyy')}-${new Date().getTime()}.csv`;
              a.click();

              URL.revokeObjectURL(url);

              toast.success('Bestand gedownload');
            })
            .catch((e) => {
              console.error(e);
              toast.error('Er is iets mis gegaan');
            });
        }}
        validate={(values) => validate(values, {
          type: [isRequired]
        })}
      >
        {({ values }) => (
          <Form>
            <Field
              name="type"
              component={Select}
              label="Type"
              options={Object.entries(EXPORT_TYPES).map(([value, label]) => ({
                value,
                label
              }))}
              required={true}
            />
            {EXPORTS_WITHOUT_RANGE.indexOf(values.type) === -1 && (
              <StyledRow>
                <Field
                  name="startDate"
                  component={CalendarInput}
                  label="Startdatum"
                  selectableDays={ALL_DAYS}
                />
                <Field
                  name="endDate"
                  component={CalendarInput}
                  label="Einddatum"
                  selectableDays={ALL_DAYS}
                />
              </StyledRow>
            )}
            <Button type="submit" theme="accent">
              Exporteren
            </Button>
          </Form>
        )}
      </Formik>
    </StyledTab>
  );
}

const StyledTab = styled.div`
  padding: 2rem;
`;

const StyledRow = styled.div`
  display: flex;
  gap: 1rem;
  & > * {
    flex: 1;
  }
`;
