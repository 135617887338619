import styled from '@emotion/styled/macro';
import { FieldProps } from 'formik';
import React, { ComponentProps, InputHTMLAttributes, TextareaHTMLAttributes, useState } from 'react';
import { Calendar } from './Calendar';
import { format } from 'date-fns';

type Props = FieldProps & InputHTMLAttributes<HTMLInputElement> & TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string,
  margin: boolean,
  dollar?: boolean
};

const indexMixedArrayOrProperty = (obj: object, indexStr: string) => {
  if (obj === undefined) return obj;
  const indexArr = indexStr.split('.');
  if (indexArr.length === 1) return obj?.[indexArr[0]];
  return indexMixedArrayOrProperty(obj?.[indexArr.shift()!], indexArr.join('.'))
}

export function Input({ field, margin = true, dollar, form, label, meta, ...props }: Props) {
  const error = form.errors[field.name];
  const hasError = !!(error && indexMixedArrayOrProperty(form.touched, field.name));

  const handleOnBlur = () => {
    if (!field.value) {
      form.setFieldValue(field.name, 0);
    }
  };
  const handleOnFocus = () => {
    if (field.value === 0) {
      form.setFieldValue(field.name, '');
    }
  };
  return (
    <StyledWrapper margin={margin}>
      {label && (
        <StyledLabel htmlFor={field.name}>
          {label}:
          {hasError && (
            <StyledError>
              {error}
            </StyledError>
          )}
        </StyledLabel>
      )}
      {dollar ? (
        <SpanEuro>
          <StyledInput {...props} {...field} hasError={hasError} margin={margin} label={label} onBlur={handleOnBlur} onFocus={handleOnFocus} />
          <I>€</I>
        </SpanEuro>
      ) : (
        <StyledInput {...props} {...field} hasError={hasError} margin={margin} label={label} onFocus={handleOnFocus} />
      )}
    </StyledWrapper>
  );
}

export function DateInput({ field, margin = true, form, label, meta, ...props }: Props) {
  const error = form.errors[field.name];
  const hasError = !!(error && indexMixedArrayOrProperty(form.touched, field.name));

  const formattedValue = field.value ? new Date(field.value).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })
    : '';

  return (
    <StyledWrapper margin={margin}>
      {label && (
        <StyledLabel htmlFor={field.name}>
          {label}:
          {hasError && (
            <StyledError>
              {error}
            </StyledError>
          )}
        </StyledLabel>
      )}
      <StyledInput {...props} {...field} hasError={hasError} margin={margin} label={label} value={formattedValue} readOnly={true} />
    </StyledWrapper>
  );
}

type CalendarInputProps = FieldProps & ComponentProps<typeof Calendar> & {
  label: string,
  margin: boolean
};

export function CalendarInput({ field, margin = true, form, label, meta, ...props }: CalendarInputProps) {
  const [open, setOpen] = useState(false);

  const error = form.errors[field.name];
  const hasError = !!(error && indexMixedArrayOrProperty(form.touched, field.name));

  return (
    <StyledWrapper margin={margin}>
      {label && (
        <StyledLabel htmlFor={field.name}>
          {label}:
          {hasError && (
            <StyledError>
              {error}
            </StyledError>
          )}
        </StyledLabel>
      )}
      <StyledButton hasError={hasError} margin={margin} label={label} type="button" onClick={() => setOpen(true)}>
        {format(field.value, 'dd MMMM yyyy')}
      </StyledButton>
      {open && (
        <StyledCalendar>
          <Calendar
            {...props}
            value={field.value}
            onChange={(value) => {
              form.setFieldValue(field.name, value);
              setOpen(false);
            }}
          />
        </StyledCalendar>
      )}
    </StyledWrapper>
  );
}

export function TextArea({ field, margin = true, form, label, ...props }: Props) {
  const error = form.errors[field.name];
  const hasError = !!(error && indexMixedArrayOrProperty(form.touched, field.name));

  return (
    <StyledWrapper margin={margin}>
      {label && (
        <StyledLabel htmlFor={field.name}>
          {label}:
          {hasError && (
            <StyledError>
              {error}
            </StyledError>
          )}
        </StyledLabel>
      )}
      <StyledTextArea {...props} {...field} hasError={hasError} margin={margin} label={label} />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => props.margin && '1rem'};
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
  color: rgba(0, 0, 0, 0.503991);
`;

const StyledError = styled.span`
  color: red;
`;

const StyledInput = styled.input<{ hasError: boolean, margin: boolean, label: string }>`
  display: block;
  font-family: inherit;
  text-align: start;
  width: 100%;
  margin-bottom: ${(props) => props.margin && '.5rem'};
  padding: .75rem 1.5rem;
  background-color: white;
  border-radius: 1.3rem;
  box-shadow: ${(props) => props.hasError ? '0 0 3px 0 red !important' : '0 0 3px 0 rgba(0, 0, 0, .25)'};
  transition: background-color .25s ease;
  :hover {
    background-color: #F8F8F8;
  }
`;

const SpanEuro = styled.span`
    position: relative;
`;

const I = styled.i`
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
	font-style: normal;
`;

const StyledTextArea = StyledInput.withComponent('textarea');

const StyledButton = StyledInput.withComponent('button');

const StyledCalendar = styled.div`
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
`;
