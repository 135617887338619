import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useRequest } from '../hooks/useRequest';
import { Method, Role } from '../types';
import { Switch } from './common/Switch';
import { switchRole } from '../utils/switchRole';
import { useSession } from '../hooks/useSession';


type Props = {
  value: boolean,
  disabled?: boolean,
  locationId?: string,
  onSuccess?: (checked: boolean) => void,
};

export function LocationRepeatSwitch({ value, disabled = false, locationId, onSuccess, ...props }: Props) {
  const request = useRequest();
  const [checked, setChecked] = useState(value);
  const { session } = useSession();
  const url = switchRole(session.me, {
    [Role.Company]: `company/locations/${locationId}`,
    [Role.Admin]: `locations/${locationId}`
  });

  return (
    <Switch
      size={locationId ? 20 : 26}
      onChange={(checked, event) => {
        request(
          locationId ? url ?? '' : `location`,
          Method.Patch,
          {
            repeatOrders: checked
          }
        )
          .then(() => {
            setChecked(checked);
            onSuccess?.(checked);
            toast.success('Herhaalservice aangepast');
          });
      }}
      disabled={disabled}
      checked={checked}
    />
  );
}
