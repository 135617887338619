import styled from '@emotion/styled/macro';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from '../components/Button';
import { Container } from '../components/Container';
import { Entry } from '../components/Entry';
import { Input } from '../components/Input';
import { LocationRepeatSwitch } from '../components/LocationRepeatSwitch';
import { Sider } from '../components/Sider';
import { useRequest } from '../hooks/useRequest';
import { useSession } from '../hooks/useSession';
import { setRepeatOrders } from '../store/slices/sessionSlice';
import { Method, Role } from '../types';
import { switchRole } from '../utils/switchRole';
import { validate } from '../utils/validate';
import { isPassword } from '../validators/isPassword';
import { isRequired } from '../validators/isRequired';
import { ExportTab } from '../components/tabs/ExportTab';

export default function Settings() {
  const { session: sessionState } = useSession();
  const request = useRequest();
  const dispatch = useDispatch();
  const offset = switchRole(sessionState.me, {
    [Role.Location]: 3,
    [Role.Company]: 3,
    [Role.Admin]: 5.5
  });

  const settings = {
    Wachtwoord: (
      <StyledForm>
        <Formik
          initialValues={{ password: '' }}
          onSubmit={({ password }) => {
            request(`users/password/reset`, Method.Post, {
              password
            }).then(() => toast.success('Wachtwoord bijgewerkt'))
              .catch(() => toast.error('Er is iets mis gegaan'));
          }}
          validate={(values) => validate(values, {
            password: [isRequired, isPassword]
          })}
        >
          <Form>
            <Field type="password" name="password" label="Password" component={Input}/>
            <Button type="submit" theme="accent">
              Opslaan
            </Button>
          </Form>
        </Formik>
      </StyledForm>
    ),
    ...!!sessionState.company?.repeatOrders && sessionState.me?._id && {
      Herhaalservice: (
        <StyledForm>
          <RepeatOrderContainer>
            <div>Na donderdag 17.00 uur wordt je laatste bestelling automatisch herhaald.</div>
            <LocationRepeatSwitch
              value={!!sessionState.me?.repeatOrders}
              onSuccess={(checked) => dispatch(setRepeatOrders(checked))}
            />
          </RepeatOrderContainer>
        </StyledForm>
      )
    },
    ...sessionState.me?.role === Role.Admin && {
      Export: (
        <ExportTab/>
      )
    }
  };

  const [open, setOpen] = useState(Object.keys(settings)[0]);

  return (
    <Container>
      <StyledTop offset={offset}/>
      <Sider
        sidebar={{
          title: 'Instellingen',
          actions: [],
          children: Object.keys(settings).map((key) => (
            <Entry
              key={key}
              label={key}
              value={key}
              enabled={open === key}
              onClick={setOpen}
            />
          ))
        }}
        content={{
          title: open,
          actions: [],
          children: settings[open as keyof typeof settings]
        }}
        offset={3}
      />
    </Container>
  );
}

const StyledTop = styled.div<{ offset?: number }>`
  margin-top: ${(props) => props.offset}rem;
`;

const StyledForm = styled.div`
  padding: 2rem;
`;

const RepeatOrderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 9px;
`;
