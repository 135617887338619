import React from 'react';
import ReactSelect from 'react-select';

import { GREEN } from '../constants';

export function Dropdown<T>({ error, styles, ...props }: any) {
  return (
    <ReactSelect<T>
      {...props}
      styles={{
        control: (provided, { isFocused, isDisabled }) => ({
          ...provided,
          backgroundColor: 'white',
          borderRadius: '5rem',
          border: 'none',
          boxShadow: isDisabled ? '' : isFocused ? '0 0 0 2px #545454' : error ? '0 0 3px 0 red' : '0 0 3px 0 rgba(0, 0, 0, .25)',
          opacity: isDisabled ? .6 : 1,
          transition: 'box-shadow .25s ease, opacity .25s ease',
          ':hover': {
            boxShadow: !isFocused && !error && '0 0 10px 0 rgba(0, 0, 0, .25)'
          }
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '7px 1.2rem 8px'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit',
          overflow: 'initial'
        }),
        menu: (provided) => ({
          ...provided,
          borderRadius: '1.35rem',
          boxShadow: '0 0 3px 0 rgba(0, 0, 0, .25)',
          overflow: 'hidden'
        }),
        menuList: (provided) => ({
          ...provided,
          padding: 0,
          '::-webkit-scrollbar': {
            width: '4px'
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: GREEN
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: '#f5f5f5'
          }
        }),
        option: (provided, { isSelected, isFocused }) => ({
          ...provided,
          padding: '.9rem 1.3rem',
          backgroundColor: isSelected ? GREEN : isFocused ? '#e8ffc9' : 'white'
        }),
        ...styles
      }}
    />
  );
}
