import { createDakpan } from 'dakpan';
import { ComponentProps } from 'react';

import { OVERLAYS } from '../constants';
import { Overlay, OverlayProps } from '../types';

type StateProps<O extends Overlay> = Omit<ComponentProps<typeof OVERLAYS[O]>, keyof OverlayProps>;

type OverlayState = {
  visible?: Overlay,
  overlays: {
    [O in Overlay]: {
      props: StateProps<O>,
      onClose?: () => void
    }
  }
};

export const [OverlayProvider, useOverlay] = createDakpan<OverlayState>({
  overlays: Object.keys(OVERLAYS).reduce(
    (previous, current) => ({
      ...previous,
      [current]: {}
    }),
    {}
  ) as OverlayState['overlays']
})({
  openOverlay: (name: Overlay, props: StateProps<typeof name>, onClose?: () => void) => ({ overlays, ...state }) => ({
    ...state,
    visible: name,
    overlays: Object.keys(overlays).reduce(
      (previous, current) => {
        if (current !== name) {
          return {
            ...previous,
            [current]: overlays[current as Overlay]
          };
        }

        return {
          ...previous,
          [current]: {
            props,
            onClose
          }
        };
      },
      {}
    ) as OverlayState['overlays']
  }),
  closeOverlay: () => (state) => {
    const { visible } = state;

    if (visible) {
      const overlay = state.overlays[visible];

      if (overlay && overlay.onClose) {
        overlay.onClose();
      }
    }

    return {
      ...state,
      visible: undefined
    };
  }
});
