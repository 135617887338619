import styled from '@emotion/styled/macro';
import { addMonths, eachDayOfInterval, endOfMonth, format, getDay, isSameDay, startOfMonth } from 'date-fns';
import { nl } from 'date-fns/locale';
import React, { useMemo, useState } from 'react';

import { GREEN } from '../constants';
import { DeliveryDay, WeekendDay } from '../types';

import { Button } from './Button';
import { Group } from './Group';

type Props = {
  value?: Date | number,
  onChange: (date: Date) => void,
  selectableDays?: (DeliveryDay | WeekendDay)[]
};

const MONTHS_IN_YEAR = 12;
const DAYS_IN_WEEK = 7;

export function Calendar({ value, onChange, selectableDays = [0, 1, 2, 3, 4] }: Props) {
  const [month, setMonth] = useState(() => startOfMonth(value || new Date()));
  const days = useMemo(() => eachDayOfInterval({
    start: month,
    end: endOfMonth(month)
  }), [month]);

  const fixDay = (i: number) => (i === 0 ? DAYS_IN_WEEK : i) - 1;
  const moveMonth = (amount: number) => () => setMonth(addMonths(month, amount));
  const change = (date: Date) => () => onChange(date);
  const arrayOf = (length: number) => Array.from(new Array(length).keys());

  const startOffset = fixDay(getDay(days[0]));

  return (
    <StyledCalendar
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <StyledHeader>
        <Group>
          <Button onClick={moveMonth(-MONTHS_IN_YEAR)} compact={true} type="button">&lt;&lt;</Button>
          <Button onClick={moveMonth(-1)} compact={true} type="button">&lt;</Button>
        </Group>
        {format(month, 'MMMM uuuu', { locale: nl })}
        <Group>
          <Button onClick={moveMonth(1)} compact={true} type="button">&gt;</Button>
          <Button onClick={moveMonth(MONTHS_IN_YEAR)} compact={true} type="button">&gt;&gt;</Button>
        </Group>
      </StyledHeader>
      <StyledWeekdays>
        {arrayOf(DAYS_IN_WEEK).map((i) => (
          <StyledWeekday key={i}>
            {nl.localize!.day((i + 1) % DAYS_IN_WEEK, { width: 'short' })}
          </StyledWeekday>
        ))}
      </StyledWeekdays>
      <StyledDays>
        <StyledOffset startOffset={startOffset}/>
        {days.map((day) => (
          <StyledDay
            key={day.toDateString()}
            active={!!(value && isSameDay(day, value))}
            onClick={change(day)}
            disabled={selectableDays.indexOf(fixDay(getDay(day))) === -1}
          >
            {day.getDate()}
          </StyledDay>
        ))}
      </StyledDays>
    </StyledCalendar>
  );
}

const StyledCalendar = styled.div`
  width: 22.5rem;
  padding: .5rem;
  background-color: white;
  border-radius: 1.7rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  color: white;
  background-color: ${GREEN};
  border-radius: 1.5rem;
`;

const StyledWeekdays = styled.div`
  padding: .5rem .25rem;
`;

const StyledWeekday = styled.span`
  display: inline-flex;
  justify-content: center;
  width: 3rem;
`;

const StyledDays = styled.div`
  background-color: ${GREEN};
  border-radius: 1.5rem;
  padding: .25rem;
`;

const StyledOffset = styled.div<{ startOffset: number }>`
  display: inline-block;
  width: ${(props) => props.startOffset * 3}rem;
`;

const StyledDay = styled.button<{ active?: boolean, disabled?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: .25rem;
  border-radius: 50%;
  border: none;
  font-weight: normal;
  cursor: ${(props) => !props.disabled && 'pointer'};
  color: ${(props) => `rgba(0, 0, 0, ${props.disabled ? '.2' : props.active ? '1' : '.6'})`};
  background-color: ${(props) => `rgba(255, 255, 255, ${props.active ? '1' : props.disabled ? '.2' : '.6'})`};
  transition: color .25s ease, background-color .25s ease;
  :hover:not(:disabled) {
    color: ${(props) => !props.active && 'rgba(0, 0, 0, .8)'};
    background-color: ${(props) => !props.active && 'rgba(255, 255, 255, .8)'};
  }
`;
